// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualite-art-deco-js": () => import("./../src/pages/actualite-art-deco.js" /* webpackChunkName: "component---src-pages-actualite-art-deco-js" */),
  "component---src-pages-actualite-verriere-js": () => import("./../src/pages/actualite-verriere.js" /* webpackChunkName: "component---src-pages-actualite-verriere-js" */),
  "component---src-pages-actualite-villa-js": () => import("./../src/pages/actualite-villa.js" /* webpackChunkName: "component---src-pages-actualite-villa-js" */),
  "component---src-pages-decouvrir-js": () => import("./../src/pages/decouvrir.js" /* webpackChunkName: "component---src-pages-decouvrir-js" */),
  "component---src-pages-fenetres-js": () => import("./../src/pages/fenetres.js" /* webpackChunkName: "component---src-pages-fenetres-js" */),
  "component---src-pages-galerie-atelier-js": () => import("./../src/pages/galerie-atelier.js" /* webpackChunkName: "component---src-pages-galerie-atelier-js" */),
  "component---src-pages-galerie-decors-fany-glass-js": () => import("./../src/pages/galerie-decors-fany-glass.js" /* webpackChunkName: "component---src-pages-galerie-decors-fany-glass-js" */),
  "component---src-pages-galerie-projets-d-envergures-js": () => import("./../src/pages/galerie-projets-d-envergures.js" /* webpackChunkName: "component---src-pages-galerie-projets-d-envergures-js" */),
  "component---src-pages-galerie-vitrail-traditionnel-js": () => import("./../src/pages/galerie-vitrail-traditionnel.js" /* webpackChunkName: "component---src-pages-galerie-vitrail-traditionnel-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-merci-js": () => import("./../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-particulier-js": () => import("./../src/pages/particulier.js" /* webpackChunkName: "component---src-pages-particulier-js" */),
  "component---src-pages-portes-entrees-js": () => import("./../src/pages/portes-entrees.js" /* webpackChunkName: "component---src-pages-portes-entrees-js" */),
  "component---src-pages-professionnel-js": () => import("./../src/pages/professionnel.js" /* webpackChunkName: "component---src-pages-professionnel-js" */),
  "component---src-pages-tendances-js": () => import("./../src/pages/tendances.js" /* webpackChunkName: "component---src-pages-tendances-js" */),
  "component---src-pages-verandas-js": () => import("./../src/pages/verandas.js" /* webpackChunkName: "component---src-pages-verandas-js" */)
}

